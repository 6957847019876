import styled, { createGlobalStyle } from "styled-components";
import React, { useState, useEffect } from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import Helmet from "react-helmet";

import "./index.css";

import {
  NavLink,
  NavLinkHref,
  MAX_WIDTH,
  panelSpacing,
  UppercaseLabel,
  onPhone,
  onSmallScreen,
  onSmallDesktop,
  AboutSVG,
  HomeSVG,
  ProgramSVG,
  DonateSVG,
  ContactSVG,
} from "./Elements";
import { Hamburger, IconButton, Button } from "../Kit";
import { Link } from "react-router-dom";

import AboutUsComponent from "./components/aboutus";
import ProgramComponent from "./components/program";
import FourCComponent from "./components/fourc";

import GLOBAL_CONSTANTS from "../constants/config";

import benefitsBg from "./images/benefits_bg.png";
import VTSLogoWhite from "./images/vts-logo-white.png";
import whiteBox from "./images/white-box.png";
import whiteBox1 from "./images/whitebox-text1.png";
import whiteBox2 from "./images/whitebox-text2.png";
import whiteBox3 from "./images/whitebox-text3.png";

const defaultColor = "#515151";
const defaultFont = "poppins-regular";

// NAV

const Container = styled.div`
  max-width: 1440px;
  // padding: 64px 32px;
  margin: 0 auto;
  height: 100%;

  ${onPhone} {
    // padding: 32px 16px;
  }
  ${onSmallDesktop} {
    max-width: 1100px;
  }
`;

const Nav = styled.nav`
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // max-width: ${MAX_WIDTH};
  margin: 0 auto;
  background: white;

  .Hamburger {
    display: none;
  }

  ${onPhone} {
    .Hamburger {
      display: inline-block;
    }
  }
  ${onSmallScreen} {
    .Hamburger {
      display: inline-block;
    }
  }
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-around;

  ${onPhone} {
    &.NavMenu {
      display: none;
    }
  }

  ${onSmallScreen} {
    &.NavMenu {
      display: none;
    }
  }

  &.Responsive {
    display: none;
  }

  ${onPhone} {
    &.Responsive {
      display: block;
    }
  }
  ${onSmallScreen} {
    &.Responsive {
      display: block;
    }
  }
`;

const Benefits = styled.div`
  ${UppercaseLabel} {
    margin-top: 32px;
  }

  & > *:first-child {
    flex-basis: 425px;
    padding-right: 32px;
    flex-grow: 0;
  }

  & > *:last-child {
    flex: 1;
    min-width: 0;
  }

  ${onPhone} {
    & > *:first-child {
      flex-basis: 425px;
      padding-right: 0;
      flex-grow: 0;
    }
  }
`;

// footer

const StyledFooter = styled.div`
  padding: ${panelSpacing} 1rem;
  background: #515151;
  max-width: ${MAX_WIDTH};
  margin: 0 auto;
  color: white;
`;

const SubNav = styled.div`
  display: flex;
  font-size: 17px;
  line-height: 1.8;
`;

const ResponsiveNav = styled.div`
  ${onPhone} {
    ${NavMenu} {
      display: block;

      & > a {
        display: block;
        padding: 12px 10px;
      }
    }
  }

  ${onSmallScreen} {
    ${NavMenu} {
      display: block;

      & > a {
        display: block;
        padding: 12px 10px;
      }
    }
  }
`;

const NavbarText = styled.span`
  margin-left: 10px;
  ${onSmallDesktop} {
    margin-left: 5px;
  }
`;

const FirstSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  height: 35rem;
  ${onPhone} {
    flex-direction: column;
    height: 100%;
  }
  ${onSmallScreen} {
    flex-direction: column;
    height: 100%;
  }
`;

const FirstSectionText = styled.div`
  font-size: 24px;
  padding: 0 4rem;
  width: 50%;
  color: #000000;
  ${onPhone} {
    width: 100%;
    padding: 2rem 4rem;
  }
  ${onSmallScreen} {
    width: 100%;
    padding: 2rem 4rem;
  }
`;

const PointsMarker = styled.div`
  height: 22px;
  width: 3px;
  border-right: 3px solid #f5821e;
  margin-right: 1rem;
`;

export const Navbar = (props) => {
  const [showing, setShowing] = useState(false);
  const [homeHover, setHomeHover] = useState(false);
  const [progHover, setProgHover] = useState(false);
  const [aboutHover, setAboutHover] = useState(false);
  const [donateHover, setDonateHover] = useState(false);
  const [contactHover, setContactHover] = useState(false);

  const location = window.location.pathname;
  switch (location) {
    case "/": {
      if (!homeHover) {
        setHomeHover(true);
      }
      break;
    }
    case "/aboutus":
      if (!aboutHover) {
        setAboutHover(true);
      }
      break;
    case "/program":
      if (!progHover) {
        setProgHover(true);
      }
      break;
    case "/public":
      if (!homeHover) {
        setHomeHover(true);
      }
      break;
    case "/public/aboutus":
      if (!aboutHover) {
        setAboutHover(true);
      }
      break;
    case "/public/program":
      if (!progHover) {
        setProgHover(true);
      }
      break;
  }

  return (
    <div style={{ background: "white" }}>
      <Container>
        <Nav>
          <Link to={props.publicPage ? "/public" : "/"}>
            <img
              style={{ maxWidth: "200px", marginLeft: "1rem", marginTop: "15px" }}
              src={require("./images/vts-watershed.png")}
              alt="vts-logo"
            />
          </Link>
          <IconButton
            className="Hamburger"
            label="Menu"
            onClick={() => setShowing(!showing)}
          >
            <Hamburger iconWidth={24} iconHeight={24} />
          </IconButton>
          <NavMenu className="NavMenu">
            <NavLink
              to={props.publicPage ? "/public" : "/"}
              color={homeHover ? "#f5821e" : "#606060"}
              onClick={() => {
                window.dataLayer.push({
                  event: "event",
                  eventProps: {
                    category: "Home page",
                    action: "Navigation option selected",
                    label: "Navigation | Home ",
                    value: "home",
                  },
                });
              }}
              onMouseEnter={() => {
                setHomeHover(true);
              }}
              onMouseOut={() => {
                setHomeHover(false);
              }}
            >
              <span>
                <HomeSVG color={homeHover ? "#f5821e" : "#606060"} />
              </span>
              <NavbarText>Home</NavbarText>
            </NavLink>
            <NavLink
              to={props.publicPage ? "/public" : "/"}
              color={donateHover ? "#f5821e" : "#606060"}
              onMouseEnter={() => {
                setDonateHover(true);
              }}
              onMouseOut={() => {
                setDonateHover(false);
              }}
              onClick={(e) => {
                  document.getElementById("about-vts")?.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                  });
              }}
            >
              <span>
                <DonateSVG color={donateHover ? "#f5821e" : "#606060"} />
              </span>
              <NavbarText>About VTS</NavbarText>
            </NavLink>
            <NavLink
              to={props.publicPage ? "/public/program" : "/program"}
              color={progHover ? "#f5821e" : "#606060"}
              onClick={() => {
                window.dataLayer.push({
                  event: "event",
                  eventProps: {
                    category: "Home page",
                    action: "Navigation option selected",
                    label: "Navigation | Program",
                    value: "program",
                  },
                });
              }}
              onMouseEnter={() => {
                setProgHover(true);
              }}
              onMouseOut={() => {
                setProgHover(false);
              }}
            >
              <span>
                <ProgramSVG color={progHover ? "#f5821e" : "#606060"} />
              </span>
              <NavbarText>VTS Offerings</NavbarText>
            </NavLink>
            
            {/*<NavLinkHref
              href={'https://nut.sh/ell/forms/262461/KPfZ6j'}
              target={"_blank"}
              color={contactHover ? "#f5821e" : "#606060"}
              onMouseEnter={() => {
                setContactHover(true);
              }}
              onMouseOut={() => {
                setContactHover(false);
              }}
            >
              <span>
                <ContactSVG color={contactHover ? "#f5821e" : "#606060"} />
              </span>
              <NavbarText>Newsletter</NavbarText>
            </NavLinkHref>*/}
            {!props.publicPage && (
              <NavLink
                login="true"
                to="/login"
                onClick={() => {
                  window.dataLayer.push({
                    event: "event",
                    eventProps: {
                      category: "Home page",
                      action: "Navigation option selected",
                      label: "Navigation | Login",
                      value: "true",
                    },
                  });
                }}
                style={{
                  background: "#f5821e",
                  padding: "10px 30px",
                  borderRadius: "22px",
                }}
              >
                User Login
              </NavLink>
            )}
            {props.publicPage && (
              <NavLink
                login="true"
                to="/"
                style={{
                  background: "#f5821e",
                  padding: "10px 30px",
                  borderRadius: "22px",
                }}
              >
                My Dashboard
              </NavLink>
            )}
            {/* <NavLinkPlain>
              <div onClick={props.login('google')}>
              <img 
                onMouseEnter={e => (e.currentTarget.src = signInHover)}
                onMouseOut={e => (e.currentTarget.src = signIn)}
                alt="signIn"
                style={{
                  maxWidth: "200px",
                }}
                src={signIn}
              />
              </div>
            </NavLinkPlain> */}
          </NavMenu>
        </Nav>
      </Container>
      {showing && (
        <ResponsiveNav>
          <NavMenu className="Responsive">
            <NavLink
              to="/"
              color={homeHover ? "#f5821e" : "#606060"}
              onMouseEnter={() => {
                setHomeHover(true);
              }}
              onMouseOut={() => {
                setHomeHover(false);
              }}
            >
              <span>
                <HomeSVG color={homeHover ? "#f5821e" : "#606060"} />
              </span>
              <span style={{ marginLeft: "10px" }}> Home</span>
            </NavLink>
            <NavLink
              to={props.publicPage ? "/public/program" : "/program"}
              color={progHover ? "#f5821e" : "#606060"}
              onMouseEnter={() => {
                setProgHover(true);
              }}
              onMouseOut={() => {
                setProgHover(false);
              }}
            >
              <span>
                <ProgramSVG color={progHover ? "#f5821e" : "#606060"} />
              </span>
              <span style={{ marginLeft: "10px" }}> Programs</span>
            </NavLink>
            <NavLink
              to={props.publicPage ? "/public/aboutus" : "/aboutus"}
              color={aboutHover ? "#f5821e" : "#606060"}
              onMouseEnter={() => {
                setAboutHover(true);
              }}
              onMouseOut={() => {
                setAboutHover(false);
              }}
            >
              <span>
                <AboutSVG color={aboutHover ? "#f5821e" : "#606060"} />
              </span>
              <span style={{ marginLeft: "10px" }}> About</span>
            </NavLink>
            <NavLinkHref
              href="https://donorbox.org/vts-watershed-curriculum-expansion-2023-2024"
              target={"_blank"}
              color={donateHover ? "#f5821e" : "#606060"}
              onMouseEnter={() => {
                setDonateHover(true);
              }}
              onMouseOut={() => {
                setDonateHover(false);
              }}
            >
              <span>
                <DonateSVG color={donateHover ? "#f5821e" : "#606060"} />
              </span>
              <span style={{ marginLeft: "10px" }}> Donate</span>
            </NavLinkHref>
            <NavLinkHref
              href={'https://nut.sh/ell/forms/262461/KPfZ6j'}
              target={"_blank"}
              color={contactHover ? "#f5821e" : "#606060"}
              onMouseEnter={() => {
                setContactHover(true);
              }}
              onMouseOut={() => {
                setContactHover(false);
              }}
            >
              <span>
                <ContactSVG color={contactHover ? "#f5821e" : "#606060"} />
              </span>
              <span style={{ marginLeft: "10px" }}> Newsletter</span>
            </NavLinkHref>
            {!props.publicPage && (
              <>
                <NavLink
                  login="true"
                  to="/login?register=true"
                  style={{
                    color: "#f5821e",
                    border: "1px solid #f5821e",
                    background: "#FFF",
                    padding: "10px 30px",
                    borderRadius: "22px",
                    width: "max-content",
                    margin: "10px",
                  }}
                >
                  Signup
                </NavLink>
                <NavLink
                  login="true"
                  to="/login"
                  style={{
                    background: "#f5821e",
                    padding: "10px 30px",
                    borderRadius: "22px",
                    width: "max-content",
                    margin: "10px",
                  }}
                    >
                  Login
                </NavLink>
              </>
            )}
            {props.publicPage && (
              <NavLink
                login="true"
                to="/"
                style={{
                  background: "#f5821e",
                  padding: "10px 30px",
                  borderRadius: "22px",
                  width: "max-content",
                  margin: "10px",
                }}
              >
                My Dashboard
              </NavLink>
            )}
          </NavMenu>
        </ResponsiveNav>
      )}
    </div>
  );
};

const BgPanel = styled.div`
  background-size: cover;
  height: 700px;
  width: 100%;
  padding: 0;
  margin: 0;

  ${onPhone} {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 100%;
  }
`;

const BgPanelSherman = styled(BgPanel)`
  background: url(${require("./images/Sherman.png")}) no-repeat center center;
  background-size: cover;
`;

const BgPanelPippin = styled(BgPanel)`
  background: url(${require("./images/pippin.png")}) no-repeat center center;
  background-size: cover;
`;

const BgPanelKahlo = styled(BgPanel)`
  background: url(${require("./images/kahlo.png")}) no-repeat center center;
  background-size: cover;
`;

const CenterBottomPanel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 1rem;

  ${onPhone} {
    width: fit-content;
    height: fit-content;
  }
`;

const MouseOverImg = styled.img`
  width: 12%;
  marginbottom: 1rem;
  ${onPhone} {
    width: 35%;
  }
`;

const WhiteBoxPanel = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  height: 180px;
  width: 180px;
  background: url(${whiteBox1}) no-repeat center center;
  background-size: cover;

  &:hover {
    background: url(${whiteBox1}) no-repeat center center;
  }

  ${onPhone} {
    position: initial;
    margin: 1rem;
  }
`;

const WhiteBoxPanel2 = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  height: 180px;
  width: 180px;
  background: url(${whiteBox2}) no-repeat center center;
  background-size: cover;

  &:hover {
    background: url(${whiteBox2}) no-repeat center center;
  }

  ${onPhone} {
    position: initial;
    margin: 1rem;
  }
`;
const WhiteBoxPanel3 = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  height: 180px;
  width: 180px;
  background: url(${whiteBox3}) no-repeat center center;
  background-size: cover;

  &:hover {
    background: url(${whiteBox3}) no-repeat center center;
  }

  ${onPhone} {
    position: initial;
    margin: 1rem;
  }
`;

export const FlexButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 4rem;
  flex-wrap: wrap;
  ${onPhone} {
    justify-content: space-around;
  }
`;

export const FlexButtonsBottom = styled(FlexButtons)`
  margin: 0;
  align-items: flex-start;
`;

export const FlexButton = styled.div`
  background: ${(props) => (props.first ? "#f5821e" : "#ffffff")};
  color: ${(props) => (props.first ? "#ffffff" : "#f5821e")};
  font-size: 24px;
  text-align: center;
  min-width: 370px;
  border-radius: 30px;
  border: 2px solid #f5821e;
  a {
    text-decoration: none;
    color: ${(props) => (props.first ? "#ffffff" : "#f5821e")};
  }
  &:hover {
    box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 60%);
  }
  ${onPhone} {
    margin: 2rem;
    min-width: 0;
    padding: 0rem 1rem;
  }
`;

export const FlexButtonBottom = styled(FlexButton)`
  padding: 15px;
  border-radius: 40px;
  color: ${(props) => props.color};
  border: 1px solid ${(props) => props.borderColor};
  a {
    text-decoration: none;
    color: ${(props) => props.color};
  }
  ${onPhone} {
    margin: 1rem 0;
    min-width: 0;
    padding: 1rem;
  }
`;

const BenefitsSection = styled.div`
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 60px 0;
  ${onPhone} {
    height: 100%;
  }
`;

const BenefitsHeader = styled.div`
  font-size: 32px;
  font-weight: 600;
  padding: 0 4rem;
`;

const SideVideoContainer = styled.div`
  // width: 40%;
  ${onPhone} {
    width: 100%;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${onPhone} {
    flex-direction: column;
  }
  ${onSmallScreen} {
    flex-direction: column;
  }
`;

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`;

const SideTextContainer = styled.div`
  width: 60%;
  text-align: left;
  margin: 2rem 3rem 2rem 0;
  ${onPhone} {
    width: 100%;
    margin: 0;
  }
`;

const SideText = styled.div`
  color: ${(props) => (props.highlight ? "#000" : defaultColor)};
  font-family: ${(props) => (props.highlight ? "poppins-bold" : defaultFont)};
  font-size: 18px;
  margin-bottom: ${(props) => (props.highlight ? "1px" : "2vh")};
  text-transform: ${(props) => (props.highlight ? "uppercase" : "none")};
  .button {
    color: white;
    background: #515151;
    padding: 5px 20px;
    width: max-content;
    font-size: 18px;
    cursor: pointer;
    &:hover {
      box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 60%);
    }
  }
`;

export const BigText = styled.div`
  letter-spacing: -0.84px;
  line-height: 52px;
  font-size: 48px;
  width: 75%;
  font-family: "crimsontext";
  color: ${(props) => (!props.highlight ? "#EE8B44" : defaultColor)};
`;

export const BorderButton = styled(Flex)`
  padding: 2px 30px;
  // border: 8px solid;
  // border-radius: 5px;
  // border-color: ${(props) => props.borderColor || "white"};
  color: ${(props) => props.color || "white"};
  width: max-content;
  font-size: 20px;
  font-family: "poppins-regular";
  line-height: 36px;
  letter-spacing: initial;
  margin-top: 3vh;
  cursor: pointer;
  background: #515151;

  &:hover {
    // border-color: ${defaultColor};
    box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 60%);
  }
`;

export const BorderSVG = styled.div`
  margin-left: 10rem;
  position: absolute;
  margin-top: -50px;
  ${onPhone} {
    display: none;
  }
`;

const EquationBox = styled(Flex)`
  margin: 2rem;
  ${onSmallDesktop} {
    margin: 1rem;
  }
`;

const TextSection = styled.div`
  width: 50%;
  padding: 4rem;
  font-size: 22px;
  color: #575757;
  ${onPhone} {
    width: 90%;
  }
`;

const ImpactBg = styled(Container)`
  height: 88vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${require("./images/impact_bg.png")});
  background-size: cover;
  background-repeat: no-repeat;
  ${onPhone} {
    flex-direction: column;
    background: none;
    height: 100%;
    width: 100%;
  }
  ${onSmallScreen} {
    flex-direction: column;
    background: none;
    height: 100%;
    width: 100%;
  }
`;

const ImpactSection = styled(Flex)`
  height: 70vh;
  color: #515151;
  padding-top: 5rem;
  ${onPhone} {
    height: 100%;
    width: 100%;
    padding-top: 0;
    background: #e0bf666;
  }
  ${onSmallScreen} {
    height: 100%;
    width: 100%;
    padding-top: 0;
    background: #e0bf666;
  }
`;

const ImpactHeader = styled(FlexColumn)`
  // background: #e0ebf6;
  height: 100%;
  width: 30%;
  font-size: 70px;
  padding-left: 2rem;
  align-items: flex-end;
  ${onPhone} {
    width: 100%;
    background: #e0ebf6;
  }
  ${onSmallScreen} {
    width: 100%;
    background: #e0ebf6;
  }
`;

const BenefitsWrapper = styled.div`
  background: url(${benefitsBg}) no-repeat center center;
  background-size: cover;
`;

const FooterContainer = styled(FlexColumn)`
  font-family: ${defaultFont};
  font-size: 16px;
  height: 30vh;
  width: 100%;
  color: #ffffff;
  justify-content: space-between;
  margin: 0 auto;
  ${onPhone} {
    height: 100%;
  }
  ${onSmallScreen} {
    height: 100%;
  }
`;

const FooterOptions = styled.div`
  width: 65%;
  justify-content: space-between;
  padding: 1rem 0;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  a {
    color: #ffffff;
    text-decoration: none;
    padding: 3px 15px 5px 0;
    ${onPhone} {
      padding-bottom: 20px;
    }
  }
  ${onPhone} {
    width: 100%;
    align-items: center;
    flex-direction: column;
  }
  ${onSmallScreen} {
    width: 100%;
    align-items: center;
    flex-direction: column;
  }
`;

const FooterOptionsWrapper = styled(Flex)`
  margin-right: 10px;
  width: 50%;
  height: 100%;
  justify-content: space-between;
  align-items: flex-start;
  ${onPhone} {
    align-items: center;
    width: 100%;
    text-align: center;
  }
  ${onSmallScreen} {
    align-items: center;
    width: 100%;
    text-align: center;
  }
`;

export const FooterComponent = (props) => {
  return (
    <div style={{ background: "rgb(81, 81, 81)" }}>
      <FooterContainer>
        <Flex style={{ width: "100%", maxWidth: "1440px", margin: "0 auto" }}>
          <Flex
            style={{
              width: "50%",
              height: "100%",
              justifyContent: "start",
              alignItems: "flex-start",
            }}
          >
            {/* <div style={{width: "70%", marginTop: "-30vh"}}> */}
            <Flex style={{ marginTop: "1rem", marginLeft: "2rem" }}>
              <Link to="/">
                <img
                  alt="Watershed"
                  style={{
                    maxWidth: "200px",
                  }}
                  src={VTSLogoWhite}
                />
              </Link>
            </Flex>
            {/* </div> */}
          </Flex>
          <FooterOptionsWrapper>
            <FooterOptions>
              <Link
                style={{ width: "50%", padding: "0 10px 10px 10px" }}
                to={props.publicPage ? "/public/program" : "/program"}
                onClick={() => {
                  window.dataLayer.push({
                    event: "event",
                    eventProps: {
                      category: "footer",
                      action: "Footer option selected",
                      label: "Navigation | Home ",
                      value: "home",
                    },
                  });
                }}
              >
                Offerings
              </Link>
              <a
                style={{ width: "50%", padding: "0 10px 10px 10px" }}
                href="https://donorbox.org/vts-watershed-curriculum-expansion-2023-2024"
                target={"_blank"}
              >
                Donate
              </a>
              <Link
                style={{ width: "50%", padding: "10px 10px 10px 10px" }}
                to={props.publicPage ? "/public/aboutus" : "/aboutus"}
                onClick={() => {
                  window.dataLayer.push({
                    event: "event",
                    eventProps: {
                      category: "footer",
                      action: "Footer option selected",
                      label: "Navigation | About ",
                      value: "about",
                    },
                  });
                }}
              >
                About Us
              </Link>
              <a
                style={{ width: "50%", padding: "10px 10px 10px 10px" }}
                href={`mailto:hello@watershed-ed.org?bcc=bcc@nutshell.com&subject=I'd like to learn more about...`}
                target={"_blank"}
              >
                Contact Us
              </a>
              <a
                style={{ width: "50%", padding: "10px 10px 10px 10px" }}
                href={GLOBAL_CONSTANTS.PRIVACY_POLICY_URL}
                target={"_blank"}
                className="ppolicy"
              >
                Privacy Policy
              </a>

              <a
                style={{ width: "50%", padding: "10px 10px 10px 10px" }}
                href={GLOBAL_CONSTANTS.TNC_URL}
                target={"_blank"}
                className="ppolicy"
                onClick={() => {
                  window.dataLayer.push({
                    event: "event",
                    eventProps: {
                      category: "footer",
                      action: "Footer option selected",
                      label: "Terms and Conditions",
                      value: "true",
                    },
                  });
                }}
              >
                Terms and Conditions
              </a>
            </FooterOptions>
            <Flex
              style={{
                justifyContent: "space-around",
                width: "50%",
                margin: "1rem 0",
                flexWrap: "wrap"
              }}
            >
              <NavLink
                login="true"
                to="/login"
                onClick={() => {
                  window.dataLayer.push({
                    event: "event",
                    eventProps: {
                      category: "footer",
                      action: "Footer option selected",
                      label: "Navigation | Login",
                      value: "true",
                    },
                  });
                }}
                style={{
                  color: "#f5821e",
                  border: "1px solid #f5821e",
                  background: "#FFF",
                  padding: "10px 30px",
                  borderRadius: "22px",
                  marginBottom: "1rem",
                }}
              >
                Login
              </NavLink>
              {/*<NavLinkHref
                login="true"
                href={'https://nut.sh/ell/forms/262461/KPfZ6j'}
                target={"_blank"}
                onClick={() => {
                  window.dataLayer.push({
                    event: "event",
                    eventProps: {
                      category: "footer",
                      action: "Footer option selected",
                      label: "Newsletter subscription",
                      value: "true",
                    },
                  });
                }}
                style={{
                  border: "1px solid #f5821e",
                  background: "#f5821e",
                  padding: "10px 50px",
                  borderRadius: "22px",
                  marginBottom: "1rem",
                }}
              >
                Join our newsletter
              </NavLinkHref>*/}
            </Flex>
          </FooterOptionsWrapper>
        </Flex>
        <Flex
          style={{ width: "100%", background: "#00000033", fontSize: "12px" }}
        >
          <div style={{ padding: "12px" }}>© 2023 Watershed Collaborative</div>
        </Flex>
      </FooterContainer>
    </div>
  );
};

export default function App(props) {
  let [tabIndex, setTabIndex] = useState(0);
  let [activeTab, setActiveTab] = useState(0);
  let [websiteURLs, setWebsiteURLs] = useState({
    home_page_videos: {},
    program_page_videos: {}
  });
  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
      page: {
        url: "/",
        title: "Home page",
      },
    });
    fetch(GLOBAL_CONSTANTS.WEBSITE_URLS)
      .then((res) => res.json())
      .then((result) => {
        setWebsiteURLs(result);
      });
  }, []);

  let switchTab = (index, arg2) => {
    setTabIndex(index);
  };

  let changeTab = (index) => {
    window.dataLayer.push({
      event: "event",
      eventProps: {
        category: "Home page",
        action: "prevnextClick",
        label: "Benefits tabs switch",
        value: "true",
      },
    });
    setActiveTab(index);
    setTabIndex(index);
  };

  function panelHovered() {
    window.dataLayer.push({
      event: "event",
      eventProps: {
        category: "Home page",
        action: "Hover",
        label: "Hover on carousel box",
        value: "true",
      },
    });
  }

  return (
    <div
      className="App"
      style={{ background: "white", fontFamily: "poppins-regular"}}
    >
      <Helmet title="VTS | Visual thinking strategies" />
      <Navbar {...props} />
      
      <Container>
        <Carousel
          interval="3000"
          infiniteLoop
          showStatus={false}
          showThumbs={false}
          autoPlay
        >
          <BgPanelSherman>
            <WhiteBoxPanel
              onMouseEnter={() => panelHovered()}
              white
              top="10%"
              left="7%"
            />
            <WhiteBoxPanel2
              onMouseEnter={() => panelHovered()}
              white
              top="15%"
              left="70%"
            />
            <WhiteBoxPanel3
              onMouseEnter={() => panelHovered()}
              white
              top="65%"
              left="80%"
            />
            {/* <CenterBottomPanel>
              <MouseOverImg
                src={require("./images/mouse-over-frames.png")}
                alt="mouse-over-frames"
              />
            </CenterBottomPanel> */}
          </BgPanelSherman>
          <BgPanelPippin>
            <WhiteBoxPanel
              onMouseEnter={() => panelHovered()}
              top="39%"
              left="8%"
            />
            <WhiteBoxPanel2
              onMouseEnter={() => panelHovered()}
              top="7%"
              left="48%"
            />
            <WhiteBoxPanel3
              onMouseEnter={() => panelHovered()}
              top="64%"
              left="82%"
            />
            {/* <CenterBottomPanel>
              <MouseOverImg
                src={require("./images/mouse-over-frames.png")}
                alt="mouse-over-frames"
              />
            </CenterBottomPanel> */}
          </BgPanelPippin>
          <BgPanelKahlo>
            <WhiteBoxPanel
              onMouseEnter={() => panelHovered()}
              top="35%"
              left="6%"
            />
            <WhiteBoxPanel2
              onMouseEnter={() => panelHovered()}
              top="55%"
              left="39%"
            />
            <WhiteBoxPanel3
              onMouseEnter={() => panelHovered()}
              top="32%"
              left="69%"
            />
            {/* <CenterBottomPanel>
              <MouseOverImg
                src={require("./images/mouse-over-frames.png")}
                alt="mouse-over-frames"
              />
            </CenterBottomPanel> */}
          </BgPanelKahlo>
        </Carousel>
      </Container>
      <Container style={{ padding: "7rem 0", lineHeight: "4.3rem" }}>
        <div
          style={{
            textAlign: "center",
            maxWidth: "950px",
            margin: "0 auto",
            color: "#575757",
            lineHeight: "1.3"
          }}
        >
            <span style={{ fontSize: "45px", display: "inline" }}>
              <span style={{ color: "#f5821e" }}>V</span>isual{" "}
              <span style={{ color: "#f5821e" }}>T</span>hinking{" "}
              <span style={{ color: "#f5821e" }}>S</span>trategies
            </span><br/>
            <span style={{ fontSize: "30px", display: "inline" }}>equips PK-12 educators everywhere</span><br/>
        </div>
        <div
          style={{
            textAlign: "center",
            maxWidth: "800px",
            margin: "10px auto -7px",
            color: "#575757",
            fontSize: "17px",
            lineHeight: "1.3"
          }}>
          with a proven practice, curricula, and mindset for engaging<br />
          learners of all backgrounds and abilities and  build critical thinking,<br />
          language, and social skills to thrive in learning, life, and work.<br />
        </div>
        <FlexButtons>
          <Link
            style={{ textDecoration: "none", marginLeft: "217px" }}
            to={props.publicPage ? "/public/program" : "/program"}
            onClick={() => {
              window.dataLayer.push({
                event: "event",
                eventProps: {
                  category: "Home page",
                  action: "Program page Navigation",
                  label: "Review program details",
                  value: "true",
                },
              });
            }}
          >
            <FlexButton first style={{ fontWeight: "bold" }}>Review Offerings</FlexButton>
          </Link>
        </FlexButtons>
      </Container>
      <Container id="about-vts">
        <div style={{
            textAlign: "center",
        }}>
          <span style={{ fontSize: "32px", color: "#f5821e" }}>VTS </span>
          <span style={{ fontSize: "30px", color: "#575757" }}>is...</span>
        </div>
        <Flex style={{ padding: "4rem 0" }}>
          
          <EquationBox>
            <img
              style={{ height: "150px" }}
              src={require("./images/square1.png")}
            />
          </EquationBox>
          <EquationBox>
            <img src={require("./images/plus.png")} />
          </EquationBox>
          <EquationBox>
            <img
              style={{ height: "150px" }}
              src={require("./images/square2.png")}
            />
          </EquationBox>
          <EquationBox>
            <img src={require("./images/plus.png")} />
          </EquationBox>
          <EquationBox>
            <img
              style={{ height: "150px" }}
              src={require("./images/square3.png")}
            />
          </EquationBox>
          <EquationBox>
            <img src={require("./images/equals.png")} />
          </EquationBox>
          <EquationBox>
            <img src={require("./images/fullsquare.png")} />
          </EquationBox>
        </Flex>
        <div
            style={{
              textAlign: "center",
              maxWidth: "950px",
              margin: "0 auto",
              color: "#575757",
              lineHeight: "1.3"
            }}
          >
            <span style={{ fontSize: "15px", display: "inline", fontWeight: "bold" }}> Watershed is the home of </span> <br/>
            <span style={{ fontSize: "17px", diay: "inline", color: "#f5821e" }}> highly-accessible, evidence-based, and impactful VTS online</span> <br/>
            <span style={{ fontSize: "35px", display: "inline" }}> PK-12 Student Curriculum </span> <br/>
            <span style={{ fontSize: "15px", display: "inline", fontWeight: "bold" }}> and </span> <br/>
            <span style={{ fontSize: "35px", display: "inline" }}> Facilitator Training </span> <br/>
            <span style={{ fontSize: "17px", display: "inline", color: "#f5821e" }}> in the United States and globally</span>
        </div>
        <br/>
        <div
          style={{
            textAlign: "center",
            fontSize: "16px",
            color: "#575757",
            lineHeight: "1.3"
          }}
        >
            Hear from VTS students, teachers, parents and school leaders below, sign-up to receive<br/>
            <a
              href="https://form.typeform.com/to/E4oP9cNM?typeform-source=watershed-2.herokuapp.com"
              style={{ color: "#f5821e", textDecoration: "none"}}
            >
              a VTS image in your inbox 
            </a> 
              {" "} every Monday, access 
            <a
              href="https://www.watershed-ed.org/schools/visual-teaching-strategies/vts-history-research-and-practice"
              style={{ color: "#f5821e", textDecoration: "none"}}
            >
              {" "} VTS Open Resources
            </a>
              , or review our
            <br/>
              offerings for teachers and schools to get started and go deeper with VTS 
            <a
              href="https://watershed-2.herokuapp.com/public/program"
              style={{ color: "#f5821e", textDecoration: "none"}}
            >
              {" "} here.
            </a>
            <br/>
        </div>
        <br/>
        <br/>
      </Container>
      <div style={{ background: "#9fe2d64d" }}>
        <Container>
          <Flex style={{ padding: "2rem 0" }}>
            <TextSection>
              <div style={{ fontSize: "28px", marginBottom: "2rem" }}>
                <b>
                  Evidence-based< br/>
                  <span style={{ color: "#f5821e" }}>21st Century Learning</span>
                </b>
              </div>
              <div style={{ marginBottom: "2rem" }}>
                VTS instills{" "}
                <b>critical thinking through structured, inquiry-based discussion</b> - fostering
                capable, confident learners and improved academic outcomes in classrooms where
                VTS has been implemented in schools and other educational settings throughout
                the U.S. and across the world for over 25 years.
              </div>
              <div>
                Students of all abilities and backgrounds develop language and
                literacy, evidentiary reasoning, and other 21st century skills.
              </div>
            </TextSection>
            <div>
              <iframe
                src={websiteURLs["home_page_videos"]["general_description"]}
                width="650"
                height="370"
                style={{
                  display: "block",
                  maxWidth: "100%",
                  borderRadius: "10px",
                }}
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              />
            </div>
          </Flex>
        </Container>
      </div>
      <Container>
        <div
          style={{ maxWidth: "875px", margin: "0 auto", padding: "8rem 1rem" }}
        >
          <div style={{ fontSize: "32px", color: "#575757" }}>
            <img
              src={require("./images/quote_left.png")}
              style={{ position: "absolute", marginRight: "10px" }}
            />
            <span style={{ color: "#f5821e", marginLeft: "40px" }}>
              VTS has been the only practice
            </span>{" "}
            I’ve brought into our school that levels the playing field for all
            students
            <img
              src={require("./images/quote_right.png")}
              style={{
                position: "absolute",
                marginLeft: "5px",
                marginTop: "20px",
              }}
            />
          </div>
          <div style={{ fontSize: "18px", marginTop: "1rem" }}>
            <div>- Shandra Brown,</div>
            <div>Principal of the CREC Museum Academy</div>
          </div>
        </div>
      </Container>
      {/* Impact of VTS section */}
       <div style={{ background: "rgba(159,226,214,0.3)", padding: "3rem 0" }}>
         <Container>
            <FirstSection>
              <FirstSectionText>
                <div style={{ color: "#575757", fontSize: "42px" }}>
                  VTS in PK-12 Classrooms
                </div>
                <div style={{ padding: "10px 0" }}>
                <div
                  style={{
                    padding: "10px 2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>
                    {" "}
                    <PointsMarker />
                  </span>
                  Student-driven{" "}
                </div>
                <div
                  style={{
                    padding: "10px 2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>
                    {" "}
                    <PointsMarker />
                  </span>
                  Teacher-facilitated
                </div>
                <div
                  style={{
                    padding: "10px 2rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span>
                    {" "}
                    <PointsMarker />
                  </span>
                  Deeper Learning
                </div>
              </div>
              </FirstSectionText>
              <iframe
                src={websiteURLs.program_page_videos.general}
                width="650"
                height="370"
                style={{
                  display: "block",
                  maxWidth: "100%",
                  borderRadius: "10px",
                }}
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              />
            </FirstSection>
         </Container>
       </div>
      <BenefitsWrapper>
        <Container>
          <BenefitsSection>
            <BenefitsHeader style={{ color: "#f5821e" }}>What We See, What They Say </BenefitsHeader>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => changeTab(index)}
            >
              <TabList>
                <Tab>Students</Tab>
                <Tab>Teachers</Tab>
                <Tab>Schools</Tab>
              </TabList>
              <Carousel
                selectedItem={activeTab}
                showStatus={false}
                showIndicators={false}
                showArrows={true}
                showThumbs={false}
                onChange={(index, arg2) => setTabIndex(index)}
              >
                <TabPanel>
                  <Flex
                    style={{
                      justifyContent: "space-between",
                      padding: "0 4rem",
                    }}
                  >
                    <SideTextContainer>
                      <SideText highlight>Learning How to Learn</SideText>
                      <SideText>
                        VTS students learn to observe, infer, and ground their
                        thinking in evidence—critical skills they then apply
                        across the curriculum and throughout life.
                      </SideText>
                      <SideText highlight>
                        Collaborative Problem solving
                      </SideText>
                      <SideText>
                        VTS students listen actively, debate with civility, and
                        scaffold on each others’ perspectives—generating
                        understanding beyond what is possible alone.
                      </SideText>
                      <SideText highlight>21st Century Literacy</SideText>
                      <SideText>
                        VTS discussions enable all students to learn to express
                        themselves, build confidence in their own voices, and
                        better appreciate the perspectives of others.
                      </SideText>
                    </SideTextContainer>
                    <SideVideoContainer>
                      <iframe
                        src={
                          websiteURLs["home_page_videos"][
                            "students_description"
                          ]
                        }
                        width="650"
                        height="360"
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                      />
                    </SideVideoContainer>
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <Flex
                    style={{
                      justifyContent: "space-between",
                      padding: "0 4rem",
                    }}
                  >
                    <SideTextContainer>
                      <SideText highlight>Responsive Teaching</SideText>
                      <SideText>
                        As students share their thinking, teachers develop new
                        and more nuanced understanding of their students'
                        backgrounds, needs, abilities, and interests.
                      </SideText>
                      <SideText highlight>An Integral Practice</SideText>
                      <SideText>
                        As they find comfort and success during VTS discussions,
                        teachers apply VTS techniques to facilitate
                        student-driven teaching and learning across the
                        curriculum.
                      </SideText>
                      <SideText highlight>A Culture of Learning</SideText>
                      <SideText>
                        As experience with VTS builds, a culture of curiosity,
                        collaboration, and evidence-based investigation grows
                        and permeates the classroom.
                      </SideText>
                    </SideTextContainer>
                    <SideVideoContainer>
                      <iframe
                        src={
                          websiteURLs["home_page_videos"][
                            "teachers_description"
                          ]
                        }
                        width="650"
                        height="360"
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                      />
                    </SideVideoContainer>
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <Flex
                    style={{
                      justifyContent: "space-between",
                      padding: "0 4rem",
                    }}
                  >
                    <SideTextContainer>
                      <SideText highlight>Pressing Priorities</SideText>
                      <SideText>
                        VTS helps educators meet standards while addressing
                        growing priorities around literacy, social and emotional
                        learning, diversity and inclusion, English language
                        learners, and more.
                      </SideText>
                      <SideText highlight>Staff Development</SideText>
                      <SideText>
                        VTS practice offers school staff a shared experience and
                        language for discussion across grades and departments.
                      </SideText>
                      <SideText highlight>Engaged Students</SideText>
                      <SideText>
                        VTS discussions are active, authentic, and experiential
                        learning - fostering student engagement that spills into
                        the next lesson, school day, and beyond.
                      </SideText>
                    </SideTextContainer>
                    <SideVideoContainer>
                      <iframe
                        src={
                          websiteURLs["home_page_videos"]["schools_description"]
                        }
                        width="650"
                        height="360"
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                      />
                    </SideVideoContainer>
                  </Flex>
                </TabPanel>
              </Carousel>
            </Tabs>
          </BenefitsSection>
        </Container>
      </BenefitsWrapper>
      <Container style={{ padding: "8rem 4rem", fontSize: "32px" }}>
        <div style={{ fontWeight: "600", marginLeft: "490px" }}>Ready to Learn More?</div>
        <FlexButtonsBottom>
          <Link
            style={{ textDecoration: "none", marginLeft: "490px" }}
            to={props.publicPage ? "/public/program" : "/program"}
            onClick={() => {
              window.dataLayer.push({
                event: "event",
                eventProps: {
                  category: "Home page",
                  action: "Program page Navigation",
                  label: "Review program details",
                  value: "true",
                },
              });
            }}
          >
            <FlexButtonBottom first color="#FFFFFF" borderColor="#f5821e" fontWeight="bold">
              Review Offerings
            </FlexButtonBottom>
          </Link>
        </FlexButtonsBottom>
      </Container>
      <FooterComponent {...props} />
    </div>
  );
}

export function Program(props) {
  window.dataLayer.push({
    event: "pageview",
    page: {
      url: "/program",
      title: "Program page",
    },
  });
  return <ProgramComponent {...props} />;
}

export function About() {
  return <div />;
}

export function Course() {
  return <div />;
}

export const AboutUs = (props) => {
  window.dataLayer.push({
    event: "pageview",
    page: {
      url: "/aboutus",
      title: "About page",
    },
  });
  return <AboutUsComponent {...props} />;
};

export function FourC(props) {
  window.dataLayer.push({
    event: "pageview",
    page: {
      url: "/4c",
      title: "Program 4C",
    },
  });
  return <FourCComponent {...props} />;
}