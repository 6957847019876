import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import Raven from "raven-js";

import { Helmet } from "react-helmet";

import auth from "../../Auth/auth";

import {
  Navbar,
  FooterComponent,
  FlexButtons,
  FlexButton,
  FlexButtonsBottom,
  FlexButtonBottom,
} from "../index";
import { onPhone, onSmallScreen, onSmallDesktop } from "../Elements";

import GLOBAL_CONSTANTS from "../../constants/config";
import Popover from "./popover";

const Container = styled.div`
  max-width: 1440px;
  // padding: 64px 32px;
  margin: 0 auto;

  ${onPhone} {
    // padding: 32px 16px;
  }
  ${onSmallDesktop} {
    max-width: 1100px;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${onPhone} {
    flex-direction: column;
  }
  ${onSmallScreen} {
    flex-direction: column;
  }
`;

const FlexColumn = styled(Flex)`
  flex-direction: column;
`

const CardsContainer = styled(Flex)`
  justify-content: space-between;
  flex-direction: column;
`;

const GroupContainer = styled(Flex)`
  justify-content: start;
  max-width: 1200px;
  margin: 2rem auto;
  width: 100%;
  height: 50rem;
  text-align: center;
  border-radius: 5px;
  flex-direction: column;
  border: 2px solid #f9f9f9;
  background: white;
  box-shadow: 1px 1px 10px 1px rgb(0 0 0 / 7%);
  ${onPhone} {
    margin: 20px;
    width: 80%;
    height: 100%;
  }
  ${onSmallScreen} {
    margin: 20px;
    width: 80%;
    height: 100%;
  }
`;

const ProgramCard = styled.div`
  width: 30%;
  box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 13%);
  padding: 2rem;
  height: 40rem;
  text-align: center;
  border-radius: 5px;
  background: #ffffff;
  ${onPhone} {
    margin: 20px;
    width: 100%;
  }
  ${onSmallScreen} {
    margin: 20px;
    width: 70%;
  }
`;

const GroupText = styled.div`
  font-size: 20px;
  font-family: "poppins-bold";
  padding: 2rem 0;
  color: #f5821e;
`;

const Header = styled.div`
  font-size: 1.3rem;
  font-weight: 700;
`;

const Price = styled.div`
  font-family: "poppins-bold";
  font-size: 30px;
  color: #f5821e;
`;

const PriceDesc = styled.div`
  font-style: italic;
`;

const ProgramDesc = styled.div``;

const HorizontalLine = styled.hr`
  margin: 2rem 0;
  height: 2px;
  color: ${(props) => props.color};
  border: none;
`;

const ProgramBeneficiariesPoint = styled.div`
  display: list-item;
  list-style-type: disc;
  position: relative;
  left: 7%;
`;

const ProgramBeneficiaries = styled.div`
  font-size: 12px;
  color: #515151;
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 200px;
  justify-content: start;
  margin: 10px auto;
  text-align: start;
`;

const CenterButton = styled.div`
  margin: 1rem 0;
  background: ${(props) => props.color};
  padding: 10px;
  font-family: "poppins-bold";
  color: #575757;
  &:hover {
    box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 40%);
  }
`;

const FirstSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  height: 35rem;
  ${onPhone} {
    flex-direction: column;
    height: 100%;
  }
  ${onSmallScreen} {
    flex-direction: column;
    height: 100%;
  }
`;

const FirstSectionText = styled.div`
  font-size: 24px;
  padding: 0 4rem;
  width: 50%;
  color: #000000;
  ${onPhone} {
    width: 100%;
    padding: 2rem 4rem;
  }
  ${onSmallScreen} {
    width: 100%;
    padding: 2rem 4rem;
  }
`;

const PlayBtn = styled.img`
  max-height: 8rem;
  position: relative;
  top: -19rem;
  left: 20rem;
  opacity: 0.8;
  ${onPhone} {
    top: -14rem;
    left: 10rem;
  }
`;

const ReviewButton = styled.div`
  color: white;
  background: #f5821e;
  padding: 15px 30px;
  border-radius: 40px;
  // font-size: 24px;
  width: fit-content;
  margin-top: 3rem;
  cursor: pointer;
  font-size: 22px;
  a {
    text-decoration: none;
    color: white;
  }
  &:hover {
    box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 40%);
  }
`;

const WatchNowSection = styled(Flex)`
  ${onPhone} {
    display: none;
  }
`;

const WatchNow = styled.div`
  margin-right: -30px;
  font-size: 12px;
  color: black;
  font-weight: bold;
`;

const WhyVTSImage = styled.img`
  border-radius: 10px;
  max-width: 650px;
  ${onPhone} {
    max-width: 100%;
  }
`;

const OfferingsImage = styled.img`
  border-radius: 5px;
  max-width: 650px;
  ${onPhone} {
    max-width: 100%;
  }
`;

const VtsOferringsImage = styled.img`
  border-radius: 5px;
  max-width: 200px;
  margin-top: 30px;
  ${onPhone} {
    max-width: 100%;
  }
`;

const VtsOferringsSR1 = styled.img`
  position: absolute;
  border-radius: 5px;
  max-width: 200px;
  margin-top: 30px;
  width: 120px;
  height: 120px;
  top: 0px;
  left: 10px;
  transform: rotate(0deg);
  zIndex: 1;
  ${onPhone} {
    max-width: 100%;
  }
`;

const VtsOferringsSR2 = styled.img`
  border-radius: 5px;
  max-width: 200px;
  margin-top: 30px;
  width: 120px;
  height: 120px;
  top: 15px;
  left: 25px;
  transform: rotate(-5deg);
  zIndex: 2;
  ${onPhone} {
    max-width: 100%;
  }
`;

const VtsOferringsSR3 = styled.img`
  position: absolute;
  border-radius: 5px;
  max-width: 200px;
  margin-top: 30px;
  width: 120px;
  height: 120px;
  top: 30px;
  left: 40px;
  transform: rotate(5deg);
  zIndex: 3;
  ${onPhone} {
    max-width: 100%;
  }
`;

const PointsMarker = styled.div`
  height: 22px;
  width: 3px;
  border-right: 3px solid #f5821e;
  margin-right: 1rem;
`;

export default function Program(props) {
  let [redirectToReferer, setRedirectToReferer] = useState(false);
  let [websiteURLs, setWebsiteURLs] = useState({
    program_page_videos: {},
    typeform_urls: {},
  });
  const [showPopover, setShowPopover] = useState(false);

  useEffect(() => {
    fetch(GLOBAL_CONSTANTS.WEBSITE_URLS)
      .then((res) => res.json())
      .then((result) => {
        setWebsiteURLs(result);
      });
    setTimeout(() => {
      setShowPopover(false); // Disabling the pop up as false on 23.01.24 as per Nick's Request. Can enable when it is required
    }, 5000);
  }, []);

  let login = (provider) => {
    return async () => {
      const subscribe = false;

      try {
        await auth.authenticate(provider, subscribe);
        setRedirectToReferer(true);
      } catch (err) {
        if (err && err.error && err.error.code === "cancelled") {
        }

        Raven.captureException(err);
        console.error("Error", err);
      }
    };
  };

  if (redirectToReferer) {
    return <Redirect to="/" />;
  }

  function programClicked(label) {
    window.dataLayer.push({
      event: "event",
      eventProps: {
        category: "Program page",
        action: "Program selected",
        label: `Program ${label}`,
        value: "true",
      },
    });
  }

  return (
    <div
      className="App"
      style={{
        background: "white",
        fontFamily: "poppins-regular",
        color: "#515151",
      }}
    >
    
      <Helmet
        title="VTS K12 School program | Visual Thinking Strategies curriculum and professional development"
        meta={[
        {
          name: "title",
          content: "VTS K12 School program",
        },
        {
          name: "description",
          content: "Visual Thinking Strategies curriculum and professional development"
        }
        ]}
      />
      <Navbar login={login} {...props} />
      {/* <div style={{maxWidth: "1200px", margin: "3rem auto 1rem"}}>
        <div style={{fontSize: "40px", fontFamily: "poppins-bold"}}>Teacher and School Subscriptions</div>
        <div style={{fontSize: "25px"}}>Enrich every classroom with rich discussion and responsive instruction through Visual Thinking Strategies.</div>
      </div> */}
      { showPopover ? <Popover /> : null }
      <Flex style={{ maxWidth: "1200px", margin: "0 auto", padding: "4rem 0" }}>
        <FlexColumn style={{ width: "75%", textAlign: "center" }}>
          <div
            style={{
              textAlign: "center",
              maxWidth: "950px",
              margin: "0 auto",
              color: "#575757",
              lineHeight: "1.3"
            }}
          >
            <span style={{ fontSize: "15px", display: "inline" }}> Watershed is the home of </span> <br/>
            <span style={{ fontSize: "17px", diay: "inline", fontWeight: "bold" }}> highly-accessible, evidence-based, and impactful VTS online</span> <br/>
            <span style={{ fontSize: "35px", display: "inline", color: "#f5821e" }}> PK-12 Student Curriculum </span> <br/>
            <span style={{ fontSize: "15px", display: "inline" }}> and </span> <br/>
            <span style={{ fontSize: "35px", display: "inline", color: "#f5821e" }}> Facilitator Training </span> <br/>
            <span style={{ fontSize: "15px", display: "inline", fontWeight: "bold" }}> in the United States and globally. </span>
          </div>
        <div
          style={{
            textAlign: "center",
            maxWidth: "800px",
            margin: "20px auto 0px",
            color: "#575757",
            fontSize: "16px",
            lineHeight: "1.3"
          }}>
          
          Download a PDF overview of Watershed’s packages and approach to VTS professional <br />
          development and curriculum adoption and review, purchase, and access all VTS at <br />
          Watershed PK-12 offerings immediately and at-your-own-pace below on this page. <br />
          
        </div>
        </FlexColumn>
      </Flex>
      {/* cards */}
      <CardsContainer>
          <GroupContainer style={{ flexDirection: "column", height: "100%" }}>
            <GroupText>VTS Offerings</GroupText>
              {/* First Row */}
              <Flex style={{ justifyContent: "space-around", width: "90%" }}>
                <ProgramCard style={{ height: "65%" }}>
                  <div style={{ height: "65%" }}>
                    <Header>VTS Daily Image Email</Header>
                    <div className="column">
                      <VtsOferringsImage src={require("../images/daily-image.png")} />
                    </div>
                    <ProgramBeneficiaries>
                      <ProgramBeneficiariesPoint>One Image Daily</ProgramBeneficiariesPoint>
                      <ProgramBeneficiariesPoint>In Your Inbox</ProgramBeneficiariesPoint>
                      <ProgramBeneficiariesPoint>Free</ProgramBeneficiariesPoint>
                    </ProgramBeneficiaries>
                  </div>
                  <div>
                    <a
                      style={{ color: "#575757", textDecoration: "none" }}
                      href="https://form.typeform.com/to/E4oP9cNM"
                      target={"_blank"}
                    >
                    <CenterButton  style={{background: "#f5821e", color: "#FFFFFF"}}>
                      Sign Up
                    </CenterButton>
                    </a>
                  </div>
                </ProgramCard>
                <ProgramCard style={{ height: "65%" }}>
                  <div style={{ height: "65%" }}>
                    <Header>VTS Open Resources</Header>
                    <div className="column">
                      <VtsOferringsImage src={require("../images/open-resources.png")} />
                    </div>
                    <ProgramBeneficiaries>
                      <ProgramBeneficiariesPoint>Images, Techniques, Research</ProgramBeneficiariesPoint>
                      <ProgramBeneficiariesPoint>History, Founders, Readings</ProgramBeneficiariesPoint>
                      <ProgramBeneficiariesPoint>Free</ProgramBeneficiariesPoint>
                    </ProgramBeneficiaries>
                  </div>
                  <div>
                    <a
                      style={{ color: "#575757", textDecoration: "none" }}
                      href="https://www.watershed-ed.org/schools/visual-teaching-strategies/vts-history-research-and-practice"
                      target={"_blank"}
                    >
                    <CenterButton  style={{background: "#f5821e", color: "#FFFFFF"}}>
                      Sign Up
                    </CenterButton>
                    </a>
                  </div>
                </ProgramCard>
                <ProgramCard style={{ height: "65%" }}>
                  <div style={{ height: "65%" }}>
                    <Header>VTS Teacher Starter Kit</Header>
                    <div className="column">
                      <VtsOferringsImage src={require("../images/starter-pack.png")} />
                    </div>
                    <ProgramBeneficiaries>
                      <ProgramBeneficiariesPoint>Get Started With VTS Today</ProgramBeneficiariesPoint>
                      <ProgramBeneficiariesPoint>1-2 Hours PD, 3+ Starter Lessons</ProgramBeneficiariesPoint>
                      <ProgramBeneficiariesPoint>$25</ProgramBeneficiariesPoint>
                    </ProgramBeneficiaries>
                  </div>
                  <div>
                    <a
                      style={{ color: "#575757", textDecoration: "none" }}
                      href="https://www.watershed-ed.org/schools/visual-teaching-strategies/vts-basics-pk-12-professional-learning-module-starter-pack"
                      target={"_blank"}
                    >
                    <CenterButton  style={{background: "#f5821e", color: "#FFFFFF"}}>
                      Sign Up
                    </CenterButton>
                    </a>
                  </div>
                </ProgramCard>
              </Flex>
              {/* Second Row */}
              <Flex style={{ justifyContent: "space-around", width: "90%", marginTop: "20px", marginBottom: "30px" }}>
                <ProgramCard style={{ height: "65%" }}>
                  <div style={{ height: "65%" }}>
                    <Header>All-access Package</Header>
                    {/* Overlapping Image Collage in a Triangle Formation */}
                    <div style={{ 
                      position: "relative",
                      marginLeft: "40px"
                    }}>
                      <VtsOferringsSR1 src={require("../images/offerings-r2-c1.1.png")} />
                      <VtsOferringsSR2 src={require("../images/offerings-r2-c1.2.png")} />
                      <VtsOferringsSR3 src={require("../images/offerings-r2-c1.3.png")} />
                    </div>
                    <ProgramBeneficiaries style={{ marginTop: "60px" }}>
                      <ProgramBeneficiariesPoint>All 400+ PK-12 Student Curriculum Images</ProgramBeneficiariesPoint>
                      <ProgramBeneficiariesPoint>All VTS Training Resources (w/Philip Yenawine)</ProgramBeneficiariesPoint>
                      <ProgramBeneficiariesPoint>$100</ProgramBeneficiariesPoint>
                    </ProgramBeneficiaries>
                  </div>
                  <div>
                    <a
                      style={{ color: "#575757", textDecoration: "none" }}
                      href="https://www.watershed-ed.org/schools/visual-teaching-strategies/all-access-package"
                      target={"_blank"}
                    >
                    <CenterButton  style={{background: "#f5821e", color: "#FFFFFF"}}>
                      Purchase
                    </CenterButton>
                    </a>
                  </div>
                </ProgramCard>
                <ProgramCard style={{ height: "65%" }}>
                  <div style={{ height: "65%" }}>
                    <Header>Curriculum Only</Header>
                    {/* Overlapping Image Collage in a Triangle Formation */}
                    <div style={{ 
                      position: "relative",
                      marginLeft: "40px"
                    }}>
                      <VtsOferringsSR1 src={require("../images/offerings-r2-c2.1.png")} />
                      <VtsOferringsSR3 src={require("../images/offerings-r2-c2.3.png")} />
                      <VtsOferringsSR2 src={require("../images/offerings-r2-c2.2.png")} />
                    </div>
                    <ProgramBeneficiaries style={{ marginTop: "60px" }}>
                      <ProgramBeneficiariesPoint>All 400+ PK-12 Student Curriculum Images</ProgramBeneficiariesPoint>
                      <ProgramBeneficiariesPoint>All VTS Training Resources (w/Philip Yenawine)</ProgramBeneficiariesPoint>
                      <ProgramBeneficiariesPoint>$50</ProgramBeneficiariesPoint>
                    </ProgramBeneficiaries>
                  </div>
                  <div>
                    <a
                      style={{ color: "#575757", textDecoration: "none" }}
                      href="https://www.watershed-ed.org/schools/visual-teaching-strategies/vts-pk-12-student-image-curriculum-1"
                      target={"_blank"}
                    >
                    <CenterButton  style={{background: "#f5821e", color: "#FFFFFF"}}>
                      Purchase
                    </CenterButton>
                    </a>
                  </div>
                </ProgramCard>
                <ProgramCard style={{ height: "65%" }}>
                  <div style={{ height: "65%" }}>
                    <Header>Teacher PD Only</Header>
                    {/* Overlapping Image Collage in a Triangle Formation */}
                    <div style={{ 
                      position: "relative",
                      marginLeft: "40px"
                    }}>
                      <VtsOferringsSR2 src={require("../images/offerings-r2-c3.2.jpg")} />
                      <VtsOferringsSR3 src={require("../images/offerings-r2-c3.3.jpg")} />
                      <VtsOferringsSR1 src={require("../images/offerings-r2-c3.1.jpg")} />
                    </div>
                    <ProgramBeneficiaries style={{ marginTop: "60px" }}>
                      <ProgramBeneficiariesPoint>All VTS Training Modules, FAQs, Discussion Videos</ProgramBeneficiariesPoint>
                      <ProgramBeneficiariesPoint>Featuring VTS Co-Founder Philip Yenawine</ProgramBeneficiariesPoint>
                      <ProgramBeneficiariesPoint>$50</ProgramBeneficiariesPoint>
                    </ProgramBeneficiaries>
                  </div>
                  <div>
                    <a
                      style={{ color: "#575757", textDecoration: "none" }}
                      href="https://www.watershed-ed.org/schools/visual-teaching-strategies/vts-basics-pk-12-professional-learning-module"
                      target={"_blank"}
                    >
                    <CenterButton  style={{background: "#f5821e", color: "#FFFFFF"}}>
                      Purchase
                    </CenterButton>
                    </a>
                  </div>
                </ProgramCard>
              </Flex>
              {/* Third Row */}
              <Flex style={{ justifyContent: "space-around", width: "90%",  marginTop: "-10px", marginBottom: "50px" }}>
                <ProgramCard style={{ height: "35%" }}>
                  <div>
                    <Header>Watershed Founding Partners</Header>
                  </div>
                  <div>
                    <a
                      style={{ color: "#575757", textDecoration: "none" }}
                      href="https://docs.google.com/document/d/1DsCXf_SjSTCa7OWb-qjYIM9XngrmVPOkyUNwN8HeyzY/edit?usp=sharing"
                      target={"_blank"}
                    >
                    <CenterButton  style={{background: "#f5821e", color: "#FFFFFF"}}>
                      Learn More
                    </CenterButton>
                    </a>
                  </div>
                </ProgramCard>
                <ProgramCard style={{ height: "45%" }}>
                  <div style={{ marginBottom: "40px" }}>
                    <Header>Community Access Partners</Header>
                  </div>
                  <div>
                    <a
                      style={{ color: "#575757", textDecoration: "none" }}
                      href="https://form.typeform.com/to/aqfl5XtG"
                      target={"_blank"}
                    >
                    <CenterButton  style={{background: "#f5821e", color: "#FFFFFF"}}>
                      Learn More
                    </CenterButton>
                    </a>
                  </div>
                </ProgramCard>
                <ProgramCard style={{ height: "45%" }}>
                  <div style={{ marginBottom: "40px" }}>
                    <Header>Global Access Partners</Header>
                    
                  </div>
                  <div>
                    <a
                      style={{ color: "#575757", textDecoration: "none" }}
                      href="https://form.typeform.com/to/FsUyYmgN"
                      target={"_blank"}
                    >
                    <CenterButton  style={{background: "#f5821e", color: "#FFFFFF"}}>
                      Learning
                    </CenterButton>
                    </a>
                  </div>
                </ProgramCard>
              </Flex>
        </GroupContainer>
      </CardsContainer>
      
      <FooterComponent {...props} />
    </div>
  );
}